<template>
  <v-data-table
    :headers="headersSurcharges"
    :items="niveau.surcharges"
    sort-by="position"
    :hide-default-footer="true"
    no-data-text="Pas de part locale surchargée"
    class="elevation-5"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Surcharge locale des Niveau d'Adhésion</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer />
        <v-btn
          class="primary mb-2"
          :disabled="disabled"
          @click="create()"
        >
          Surcharger ce niveau
        </v-btn>
      </v-toolbar>
    </template> 
    <template v-slot:item.actions="{ item }">
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              :disabled="disabled"
              v-on="on"
              @click.stop
            >
              more_vert
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-title>
                Modifier
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="suppr(item)">
              <v-list-item-title>
                Supprimer
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template v-slot:item.montant_surcharge="{ item }">
      <div class="text-right">
        {{ item.montant_surcharge | currency }}
      </div>
    </template>
    <template v-slot:item.montant_total="{ item }">
      <div class="text-right">
        {{ item.montant_surcharge + item.montant_departemental + item.montant_national | currency }}
      </div>
    </template>
  </v-data-table> 
</template>

<script>
export default {
  name: 'ListNASurchargeComponent',
  props: {
    niveau: { type: Object, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      headersSurcharges: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Libelle', value: 'libelle', sortable: false },
        { text: 'Code', value: 'cl.code', sortable: false },
        { text: 'Conseil Local', value: 'cl.nom', sortable: false },
        { text: 'Montant part locale surchargé', value: 'montant_surcharge', sortable: false },
        { text: 'Montant Adhésion Total', value: 'montant_total', sortable: false },
      ],
    };
  },
  methods: {
      edit(item) {
          this.$emit('edit', item);
      },
      create() {
        
          this.$emit('create');
      },
      suppr(item) {
          this.$emit('delete', item);
      },
  },
};
</script>