<template>
  <v-row align="center">
    <v-col>
      <v-window
        v-model="availableAnneesIds[position]"
        reverse
        class="elevation-1"
      >
        <v-window-item
          v-for="annee in niveauxAdhesion"
          :key="annee.annee_scolaire.id"
          :value="annee.annee_scolaire.id"
        >
          <v-card flat>
            <v-card-text>
              <v-row
                class="mb-4"
                align="center"
              >
                <v-btn
                  text
                  @click="prevYear"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <strong class="title">Année scolaire {{ annee.annee_scolaire.libelle }}</strong>
                <v-btn
                  text
                  @click="nextYear"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-spacer />
              </v-row>
              <v-alert
                v-if="!annee.niveau.id"
                type="error"
              >
                Aucun Niveau d'Adhésion national pour cette Année Scolaire.
              </v-alert>
              <!-- Pour le fonctionnement correct de la validation, le form n'existe pas (v-if) si il n'est pas le form vu -->
              <v-form
                v-if="availableAnneesIds[position] === annee.annee_scolaire.id"
                v-model="valid"
                :disabled="isReadOnly(annee.annee_scolaire)"
              >
                <v-text-field
                  v-model.trim="annee.niveau.show_libelle"
                  v-validate.immediate="'required|min:3|max:50'"
                  label="Libelle *"
                  data-vv-name="libelle"
                  :error-messages="errors.collect('libelle')"
                />
                <v-text-field
                  v-model="annee.niveau.show_montant"
                  v-validate.immediate="'required|decimal'"
                  required
                  type="number"
                  label="Montant Part Adhésion *"
                  data-vv-name="montant adhesion"
                  :error-messages="errors.collect('montant adhesion')"
                />
                <v-text-field
                  v-model="annee.niveau.show_montant_abonnement_sans_adhesion"
                  v-validate.immediate="'required|decimal'"
                  required
                  type="number"
                  label="Montant Abonnement sans Adhésion  *"
                  data-vv-name="montant sans adhesion"
                  :error-messages="errors.collect('montant sans adhesion')"
                />
                <v-text-field
                  v-model="annee.niveau.show_montant_abonnement_avec_adhesion"
                  v-validate.immediate="'required|decimal'"
                  required
                  type="number"
                  label="Montant Abonnement avec Adhésion *"
                  data-vv-name="montant avec adhesion"
                  :error-messages="errors.collect('montant avec adhesion')"
                />
                <v-btn
                  v-if="annee.niveau.id"
                  class="primary submit"
                  :disabled="!valid || dontHaveChange(annee)"
                  @click="validAndUpdate(annee.niveau)"
                >
                  Enregistrer
                </v-btn>
                <v-btn
                  v-else
                  class="primary submit"
                  :disabled="!valid"
                  @click="validAndCreate(annee)"
                >
                  Créer
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NiveauAdhesionNational',
  components: {
  },
  data() {
    return {
      valid: true,
      availableAnneesIds: [],
      position: 0,
    };
  },
  computed: {
    ...mapGetters('parametrages/niveauAdhesionEtAbonnement', {
      niveauxAdhesion: 'getNiveauxAdhesionNational',
    }),
    ...mapGetters('parametrages/anneeScolaire', {
      anneeCourante: 'getAnneeScolaireCourante',
    }),
  },
  created() {
    this.load().then(() =>
      this.availableAnneesIds = this.niveauxAdhesion.map(el => el.annee_scolaire.id).sort((a, b) => b - a)
    );
  },
  methods: {
    ...mapActions('parametrages/niveauAdhesionEtAbonnement', {
      load: 'loadNiveauxAdhesionNational',
      update: 'updateNiveauAdhesionEtTypeAbonnement',
      create: 'createNiveauAdhesionNationalEtTypeAbonnement',
    }),
    prevYear() {
      this.position = (this.position + 1) % this.availableAnneesIds.length;
    },
    nextYear() {
      this.position = (this.availableAnneesIds.length + this.position - 1) % this.availableAnneesIds.length;
    },
    validAndSet(niveau) {
      niveau.show_montant = Number(niveau.show_montant);
      niveau.show_montant_abonnement_sans_adhesion = Number(niveau.show_montant_abonnement_sans_adhesion);
      niveau.show_montant_abonnement_avec_adhesion = Number(niveau.show_montant_abonnement_avec_adhesion);
      if (Number.isNaN(niveau.show_montant) || Number.isNaN(niveau.show_montant_abonnement_sans_adhesion) || Number.isNaN(niveau.show_montant_abonnement_avec_adhesion))
        return false;
      niveau.libelle = niveau.show_libelle;
      niveau.montant = niveau.show_montant;
      niveau.type_abonnement_sans_adhesion.montant_unitaire = niveau.show_montant_abonnement_sans_adhesion;
      niveau.type_abonnement_avec_adhesion.montant_unitaire = niveau.show_montant_abonnement_avec_adhesion;
      return true;
    },
    validAndUpdate(niveau) {
      if (this.validAndSet(niveau)) {
        this.update(niveau);
      }
    },
    validAndCreate({ annee_scolaire, niveau }) {
      niveau.position = 0;
      niveau.type_abonnement_sans_adhesion = {
        libelle: 'Direct',
        position: 0,
        montant_unitaire: null,
      };
      niveau.type_abonnement_avec_adhesion = {
        libelle: 'Service RP',
        position: 0,
        montant_unitaire: null,
      };
      if (this.validAndSet(niveau)) {
        this.create({ annee_scolaire, niveau });
      }
    },
    dontHaveChange(annee) {
      return annee.niveau.show_libelle === annee.niveau.libelle &&
        annee.niveau.show_montant == annee.niveau.montant &&
        annee.niveau.show_montant_abonnement_sans_adhesion == annee.niveau.type_abonnement_sans_adhesion.montant_unitaire &&
        annee.niveau.show_montant_abonnement_avec_adhesion == annee.niveau.type_abonnement_avec_adhesion.montant_unitaire;
    },
    isReadOnly(annee) {
      return annee.id < this.anneeCourante.id;
    },
  },
};
</script>
