var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.niveaux.length == 0)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Aucun "+_vm._s(_vm.titre)+" n'est défini sur cette Année Scolaire ! ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.niveaux,"hide-default-footer":true,"show-expand":_vm.surchargePossible,"single-expand":true,"sort-by":"position","no-data-text":"Aucun Niveau d'Adhésion sur cette Année Scolaire"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titre))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"primary mb-2",attrs:{"disabled":_vm.disabled || _vm.niveaux.length >= 5},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Ajouter un niveau ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" more_vert ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v(" Modifier ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.suppr(item)}}},[_c('v-list-item-title',[_vm._v(" Supprimer ")])],1)],1)],1)],1)]}},{key:"item.montant",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant))+" ")])]}},{key:"item.montant_local",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_local))+" ")])]}},{key:"item.montant_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_local + item.montant + item.montant_national))+" ")])]}},(_vm.surchargePossible)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"white ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('ListNASurchargeComponent',{attrs:{"niveau":item,"disabled":_vm.disabled},on:{"edit":function (surc) { return _vm.edit(surc); },"create":function () {
            _vm.setUnavailableClIds(item.surcharges.map(function (el) { return el.cl.id; }));
            _vm.create({ pere_id: item.id_local, cl: { id: null }, montant_departemental: item.montant }); 
          },"delete":function (surc) { return _vm.suppr(surc); }}})],1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }