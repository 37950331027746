<template>
  <div>
    <v-alert
      v-if="niveaux.length == 0"
      type="error"
    >
      Aucun {{ titre }} n'est défini sur cette Année Scolaire !
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="niveaux"
      :hide-default-footer="true"
      :show-expand="surchargePossible"
      :single-expand="true"
      sort-by="position"
      class="elevation-1"
      no-data-text="Aucun Niveau d'Adhésion sur cette Année Scolaire"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ titre }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-btn
            class="primary mb-2"
            :disabled="disabled || niveaux.length >= 5"
            @click="create()"
          >
            Ajouter un niveau
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon
                :disabled="disabled"
                v-on="on"
                @click.stop
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="edit(item)">
                <v-list-item-title>
                  Modifier
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="suppr(item)">
                <v-list-item-title>
                  Supprimer
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.montant="{ item }">
        <div class="text-right">
          {{ item.montant | currency }}
        </div>
      </template>
      <template v-slot:item.montant_local="{ item }">
        <div class="text-right">
          {{ item.montant_local | currency }}
        </div>
      </template>
      <template v-slot:item.montant_total="{ item }">
        <div class="text-right">
          {{ item.montant_local + item.montant + item.montant_national | currency }}
        </div>
      </template>
      <template
        v-if="surchargePossible"
        v-slot:expanded-item="{ headers, item }"
      >
        <td
          :colspan="headers.length"
          class="white ma-0 pa-0"
        >
          <ListNASurchargeComponent
            :niveau="item"
            :disabled="disabled"
            @edit="surc => edit(surc)"
            @create="() => {
              setUnavailableClIds(item.surcharges.map(el => el.cl.id));
              create({ pere_id: item.id_local, cl: { id: null }, montant_departemental: item.montant }); 
            }"
            @delete="surc => suppr(surc)"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ListNASurchargeComponent from './ListNASurchargeComponent';
import { mapMutations } from 'vuex';

export default {
  name: 'ListNADepartementalComponent',
  components: {
    ListNASurchargeComponent,
  },
  props: {
    niveaux: { type: Array, required: true },
    titre: { type: String, default: 'Titre' },
    disabled: { type: Boolean, default: false },
    surchargePossible: { type: Boolean, default: false },
  },
  data() {
    return {
      headers: [],
      headers_base: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Libelle', value: 'libelle', sortable: false },
        { text: 'Montant part départementale', value: 'montant', sortable: false },
        { text: 'Montant part locale', value: 'montant_local', sortable: false },
        { text: 'Montant Adhésion Total', value: 'montant_total', sortable: false },
        { text: 'Code comptable', value: 'code_comptable.libelle', sortable: false },
        { text: 'Position', value: 'position' },
      ],
      headers_expand: [
        { text: 'Surcharges locales', value: 'surcharges.length' },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  watch: {
    surchargePossible(val) {
      if(val) {
        this.headers = [ ...this.headers_base, ...this.headers_expand];
      } else {
        this.headers = [ ...this.headers_base];
      }
    },
  },
  created () {
    if(this.surchargePossible) {
        this.headers = [ ...this.headers_base, ...this.headers_expand];
      } else {
        this.headers = [ ...this.headers_base];
      }
  },
  methods: {
    ...mapMutations('parametrages/niveauAdhesionEtAbonnement', {
      setUnavailableClIds: 'SET_UNAVAILABLE_CL_ID',
    }),
    edit(item) {
      this.$emit('edit', item);
    },
    create(item) {
      this.$emit('create', item);
    },
    suppr(item) {
      this.$emit('delete', item);
    },
  },
};
</script>