<template>
  <div>
    <v-stepper
      v-if="isUserNational"
      v-model="stepState"
    >
      <v-stepper-header>
        <v-stepper-step
          v-for="step in steps"
          :key="step.value"
          class="subheading"
          editable
          :step="step.value"
        >
          <template>
            {{ step.label }}
          </template>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <NiveauAdhesionNational />
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-autocomplete
            v-model="currentCdpeId"
            :items="getFilterDepartements"
            :item-text="item => `${item.code} - ${item.nom}`"
            item-value="id"
            label="Département *"
            clearable
            required="true"
          />

          <NiveauAdhesionDepartemental
            v-if="currentCdpeId"
            :key="currentCdpeId"
            :cdpe-id="currentCdpeId"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div v-else-if="isUserDepartemental">
      <v-row>
        <v-col>
          <NiveauAdhesionDepartemental 
            :cdpe-id="getUserCdpeId"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>

import { mapGetters, mapActions } from 'vuex';
import NiveauAdhesionNational from '../../../components/parametrage/niveauAdhesionEtAbonnement/National';
import NiveauAdhesionDepartemental from '../../../components/parametrage/niveauAdhesionEtAbonnement/Departemental';

export default {
  name: 'NiveauAdhesionListContainer',
  components: {
    NiveauAdhesionNational,
    NiveauAdhesionDepartemental,
  },
  data() {
    return {
      stepState: 1,
      steps: [
        {
          label: 'National',
          value: 1,
        },
        {
          label: 'Departemental/Local',
          value: 2,
        },
      ],
      currentCdpeId: 0,
    };
  },
  computed: {
    ...mapGetters('cdpe', [
      'getFilterDepartements',
    ]),
    ...mapGetters('session', [
      'isUserNational',
      'isUserDepartemental',
      'getUserCdpeId',
    ]),
  },
  created() {
    if(this.isUserNational) this.loadFilterDepartements();
  },
  methods: {
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
  },
};
</script>
