var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-5",attrs:{"headers":_vm.headersSurcharges,"items":_vm.niveau.surcharges,"sort-by":"position","hide-default-footer":true,"no-data-text":"Pas de part locale surchargée"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Surcharge locale des Niveau d'Adhésion")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"primary mb-2",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Surcharger ce niveau ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" more_vert ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v(" Modifier ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.suppr(item)}}},[_c('v-list-item-title',[_vm._v(" Supprimer ")])],1)],1)],1)],1)]}},{key:"item.montant_surcharge",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_surcharge))+" ")])]}},{key:"item.montant_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.montant_surcharge + item.montant_departemental + item.montant_national))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }