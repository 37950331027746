<template>
  <div>
    <v-row align="center">
      <v-col>
        <v-window
          v-model="availableAnneesIds[position]"
          reverse
          class="elevation-1"
        >
          <v-window-item
            v-for="annee in niveauxAdhesion"
            :key="annee.annee_scolaire.id"
            :value="annee.annee_scolaire.id"
          >
            <v-card flat>
              <v-card-text>
                <v-row
                  class="mb-4"
                  align="center"
                >
                  <v-btn
                    text
                    @click="prevYear"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <strong class="title">Année scolaire {{ annee.annee_scolaire.libelle }}</strong>
                  <v-btn
                    text
                    @click="nextYear"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-spacer />
                </v-row>
                <ListNADepartementalComponent
                  :niveaux="annee.niveaux.filter(n => n.adhesion_en_ligne == false)"
                  :disabled="isReadOnly(annee.annee_scolaire)"
                  titre="Niveau d'Adhésion"
                  surcharge-possible
                  @edit="niveau => askEdit(niveau)"
                  @delete="niveau => askDelete(niveau)"
                  @create="niveau => askCreate({ ...niveau, position: null, adhesion_en_ligne: false, annee_scolaire_id: availableAnneesIds[position] })"
                />
                <br>
                <ListNADepartementalComponent
                  :niveaux="annee.niveaux.filter(n => n.adhesion_en_ligne == true)"
                  :disabled="isReadOnly(annee.annee_scolaire)"
                  titre="Niveau d'Adhésion en Ligne"
                  @edit="niveau => askEdit(niveau)"
                  @delete="niveau => askDelete(niveau)"
                  @create="niveau => askCreate({ ...niveau, position: null, adhesion_en_ligne: true, annee_scolaire_id: availableAnneesIds[position] })"
                />
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    <ConfirmedModal
      v-if="confirmDelete"
      sentence="Supprimer ce Niveau d'Adhésion ?"
      btn-action="Supprimer"
      @close="confirmDeleteCancel()"
      @confirmed="confirmDeleteConfirm()"
    />
    <EditNAComponent
      :niveau="editedNiveau"
      @cancel="editedNiveau=undefined"
      @save="niveau => editNiveauSave(niveau)"
    />
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmedModal from './../../reusableComponents/confirmedModal';
import ListNADepartementalComponent from './ListNADepartementalComponent';
import EditNAComponent from './EditNAComponent';

export default {
  name: 'NiveauAdhesionDepartemental',
  components: {
    ConfirmedModal,
    ListNADepartementalComponent,
    EditNAComponent,
  },
  props: {
    cdpeId: { type: Number, default: 0 },
  },
  data() {
    return {
      availableAnneesIds: [],
      position: 0,
      confirmDelete: false,
      editedNiveauIsCreate: false,
      editedNiveau: undefined,
      toDeleteNiveau: undefined,
    };
  },
  computed: {
    ...mapGetters('parametrages/niveauAdhesionEtAbonnement', {
      niveauxAdhesion: 'getNiveauxAdhesionDepartemental',
      niveauxAdhesionNational: 'getNiveauxAdhesionNational',
    }),
    ...mapGetters('parametrages/anneeScolaire', {
      anneeCourante: 'getAnneeScolaireCourante',
    }),
  },
  created() {
    this.load({ cdpeId: this.cdpeId }).then(() =>
      this.availableAnneesIds = this.niveauxAdhesion.map(el => el.annee_scolaire.id).sort((a, b) => b - a)
    );
  },
  methods: {
    ...mapActions('parametrages/niveauAdhesionEtAbonnement', {
      load: 'loadNiveauxAdhesionDepartemental',
      update: 'updateNiveauAdhesion',
      createDepartemental: 'createNiveauAdhesionDepartemental',
      createSurcharge: 'createNiveauAdhesionSurcharge',
      deleteDepatemental: 'deleteNiveauAdhesionDepartemental',
      deleteSurcharge: 'deleteNiveauAdhesionSurcharge',
    }),
    prevYear() {
      this.position = (this.position + 1) % this.availableAnneesIds.length;
    },
    nextYear() {
      this.position = (this.availableAnneesIds.length + this.position - 1) % this.availableAnneesIds.length;
    },
    delete(niveau) {
      if (niveau.pere_id != undefined) {
        this.deleteSurcharge(niveau);
      } else {
        this.deleteDepatemental(niveau);
      }
    },
    askDelete(niveau) {
      this.toDeleteNiveau = niveau;
      this.confirmDelete = true;
    },
    confirmDeleteCancel() {
      this.confirmDelete = false;
      this.toDeleteNiveau = undefined;
    },
    confirmDeleteConfirm() {
      this.confirmDelete = false;
      if (this.toDeleteNiveau) {
        this.delete(this.toDeleteNiveau);
        this.toDeleteNiveau = undefined;
      }
    },
    askEdit(niveau) {
      this.editedNiveauIsCreate = false;
      this.editedNiveau = niveau;
    },
    askCreate(niveau) {
      let nat = this.niveauxAdhesionNational.find(el => el.annee_scolaire.id === niveau.annee_scolaire_id).niveau;
      let montant_nat = nat === null ? 0 : nat.montant;
      if (niveau.pere_id == undefined) { // creation departemental et local
        niveau = {
          ...niveau,
          libelle: '',
          code_comptable: { id: null, labelle: '' },
          montant: null,
          montant_local: null,
          montant_national: montant_nat,
          position_local: 0,
        };
      } else { // surcharge
        niveau = {
          ...niveau,
          libelle: '',
          montant_surcharge: null,
          montant_national: montant_nat,
        };
      }
      this.editedNiveauIsCreate = true;
      this.editedNiveau = niveau;
    },
    editNiveauSave(niveau) {
      if (this.editedNiveauIsCreate) {
        niveau.position_local = niveau.position;
        this.editedNiveau.surcharges = [];
      }
      this.valid(niveau);

      if (this.editedNiveauIsCreate)
        this.createEditedNiveau();
      else
        this.updateEditedNiveau();

      this.editedNiveau = undefined;
    },
    updateEditedNiveau() {
      if (this.editedNiveau.montant !== undefined && this.editedNiveau.montant !== null) { // departemental sur departemental
        if (Number.isNaN(this.editedNiveau.montant) || Number.isNaN(this.editedNiveau.position))
          return;
        const niveauLocal = {
          id: this.editedNiveau.id_local,
          code_comptable_id: undefined,
          libelle: this.editedNiveau.libelle,
          montant: this.editedNiveau.montant_local,
          position: this.editedNiveau.position_local,
        };
        this.update(
          {
            id: this.editedNiveau.id,
            code_comptable_id: this.editedNiveau.code_comptable.id,
            libelle: this.editedNiveau.libelle,
            montant: this.editedNiveau.montant,
            position: this.editedNiveau.position,
          }).then(() => {
            if (niveauLocal.montant !== undefined && niveauLocal.montant_local !== null) { // departemental sur local
              if (Number.isNaN(niveauLocal.montant_local) || Number.isNaN(niveauLocal.position_local))
                return;
              this.update(niveauLocal);
            }
          });
      }
      if (this.editedNiveau.montant_surcharge !== undefined && this.editedNiveau.montant_surcharge !== null) { // departemental sur local
        if (Number.isNaN(this.editedNiveau.montant_surcharge) || Number.isNaN(this.editedNiveau.position))
          return;
        this.update(
          {
            id: this.editedNiveau.id,
            code_comptable_id: undefined,
            libelle: this.editedNiveau.libelle,
            montant: this.editedNiveau.montant_surcharge,
            position: this.editedNiveau.position,
          });
      }
    },
    createEditedNiveau() {
      if (this.editedNiveau.montant !== undefined && this.editedNiveau.montant !== null &&
        this.editedNiveau.montant_local !== undefined && this.editedNiveau.montant_local !== null) { // departemental
        if (Number.isNaN(this.editedNiveau.montant) || Number.isNaN(this.editedNiveau.position) ||
          Number.isNaN(this.editedNiveau.montant_local) || Number.isNaN(this.editedNiveau.position_local))
          return;
        this.createDepartemental(this.editedNiveau);
      }
      else if (this.editedNiveau.montant_surcharge !== undefined && this.editedNiveau.montant_surcharge !== null) { // surcharge
        if (Number.isNaN(this.editedNiveau.montant_surcharge) || Number.isNaN(this.editedNiveau.position))
          return;
        this.createSurcharge(this.editedNiveau);
      }
    },
    valid(niveau) {
      if (niveau.montant !== undefined && niveau.montant !== null) { // departemental sur departemental
        niveau.montant = Number(niveau.montant);
        niveau.position = Number(niveau.position);
        if (Number.isNaN(niveau.montant) || Number.isNaN(niveau.position))
          return;
        this.editedNiveau.code_comptable = niveau.code_comptable;
        this.editedNiveau.libelle = niveau.libelle;
        this.editedNiveau.montant = niveau.montant;
        this.editedNiveau.position = niveau.position;
        this.editedNiveau.surcharges.forEach(el => el.montant_departemental = niveau.montant);
      }
      if (niveau.montant_local !== undefined && niveau.montant_local !== null) { // departemental sur local
        niveau.montant_local = Number(niveau.montant_local);
        niveau.position_local = Number(niveau.position_local);
        if (Number.isNaN(niveau.montant_local) || Number.isNaN(niveau.position_local))
          return;
        this.editedNiveau.libelle = niveau.libelle;
        this.editedNiveau.montant_local = niveau.montant_local;
        this.editedNiveau.position_local = niveau.position_local;
      }
      if (niveau.montant_surcharge !== undefined && niveau.montant_surcharge !== null) { // local sur local (surcharge)
        niveau.montant_surcharge = Number(niveau.montant_surcharge);
        niveau.position = Number(niveau.position);
        if (Number.isNaN(niveau.montant_surcharge) || Number.isNaN(niveau.position))
          return;
        this.editedNiveau.libelle = niveau.libelle;
        this.editedNiveau.montant_surcharge = niveau.montant_surcharge;
        this.editedNiveau.position = niveau.position;
        if (this.editedNiveauIsCreate) {
          this.editedNiveau.cl = niveau.cl;
        }
      }
    },
    isReadOnly(annee) {
      return annee.id < this.anneeCourante.id;
    },

  },
};
</script>
