<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    max-width="600px"
  >
    <v-card class="pa-5">
      <v-card-title>
        <span class="headline"> Niveau d'Adhésion {{ niveau ? (niveau.adhesion_en_ligne ? 'en Ligne' : '') : '' }} </span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col
              v-if="editedNiveau.libelle !== undefined"
              key="libelle"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model.trim="editedNiveau.libelle"
                v-validate.immediate="'required|min:3|max:50'"
                label="Libellé *"
                data-vv-name="libelle"
                :error-messages="errors.collect('libelle')"
              />
            </v-col>
            <v-col
              v-if="editedNiveau.montant_surcharge !== undefined"
              key="surcharge"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editedNiveau.montant_surcharge"
                v-validate.immediate="'required|decimal'"
                label="Montant Surcharge Part Locale *"
                data-vv-name="surcharge locale"
                :error-messages="errors.collect('surcharge locale')"
              />
            </v-col>
            <v-col
              v-if="editedNiveau.montant !== undefined"
              key="departement"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editedNiveau.montant"
                v-validate.immediate="'required|decimal'"
                label="Montant Part Départementale *"
                data-vv-name="part departementale"
                :error-messages="errors.collect('part departementale')"
              />
            </v-col>
            <v-col
              v-if="editedNiveau.montant_local !== undefined"
              key="local"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editedNiveau.montant_local"
                v-validate.immediate="'required|decimal'"
                label="Montant Part Locale *"
                data-vv-name="part locale"
                :error-messages="errors.collect('part locale')"
              />
            </v-col>
            <v-col
              v-if="editedNiveau.code_comptable !== undefined"
              key="codecomptable"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="codeComptableId"
                v-validate.immediate="'required'"
                :items="niveau ? niveau.adhesion_en_ligne ? codesComptablesAdhesionEnLigne : codesComptablesAdhesionManuelle : codesComptablesAdhesionManuelle"
                :item-text="item => item.libelle"
                item-value="id"
                label="Code comptable *"
                required="true"
                data-vv-name="code comptable"
                :error-messages="errors.collect('code comptable')"
              />
            </v-col>
            <v-col
              v-if="niveau.cl !== undefined && niveau.cl.id === null"
              key="conseilLocal"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="editedNiveau.cl.id"
                v-validate.immediate="'required'"
                :items="conseilLocaux"
                :item-text="item => `${item.code} - ${item.nom}`"
                item-value="id"
                label="Conseil Local *"
                required="true"
                data-vv-name="conseil local"
                :error-messages="errors.collect('conseil local')"
              />
            </v-col>
            <v-col
              v-if="editedNiveau.position !== undefined"
              key="position"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="editedNiveau.position"
                v-validate.immediate="'required|integer|min_value:1'"
                label="Position *"
                data-vv-name="position"
                :error-messages="errors.collect('position')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="primary"
          @click="dialog=false"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          :disabled="!valid || dontHaveChange()"
          @click="save()"
        >
          Enregister
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'EditNAComponent',
  props: {
    niveau: { type: Object, default: undefined },
  },
  data() {
    return {
      editedNiveau: null,
      dialog: false,
      valid: true,
      codeComptableId: 0,
    };
  },
  computed: {
    ...mapGetters('parametrages/niveauAdhesionEtAbonnement', {
      codesComptablesAdhesionManuelle : 'getCodesComptablesAdhesionManuelle',
      codesComptablesAdhesionEnLigne: 'getCodesComptablesAdhesionEnLigne',
      cdpeId: 'getCdpeId',
      unavailableCLs: 'getUnavailableClIds',
    }),
    ...mapGetters('conseilLocal', {
      filteConseilLocaux: 'getFilterConseilsLocaux',
    }),
    conseilLocaux() {
        return this.filteConseilLocaux.filter(el => !this.unavailableCLs.includes(el.id));
    },
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.$validator.pause();
        this.$emit('cancel');
        this.editedNiveau = null;
      }
    },
    niveau(val) {
      if (val === undefined || val === null) this.dialog = false;
      else {
        this.editedNiveau = JSON.parse(JSON.stringify(val));
        this.codeComptableId = val.code_comptable ? val.code_comptable.id : 0;
        this.dialog = true;
        this.$validator.resume();
      }
    },
    codeComptableId(val) {
      if (val) {
        this.editedNiveau.code_comptable.id = val;
        this.editedNiveau.code_comptable.libelle = findLibelleFromId(val);
      }
    },
  },
  created() {
    this.loadCodesComptablesAdhesionManuelle();
    this.loadCodesComptablesAdhesionEnLigne();
    this.loadConseilLocaux(this.cdpeId);
  },
  methods: {
    ...mapActions('parametrages/niveauAdhesionEtAbonnement', {
      loadCodesComptablesAdhesionManuelle: 'loadCodesComptablesAdhesionManuelle',
      loadCodesComptablesAdhesionEnLigne: 'loadCodesComptablesAdhesionEnLigne',
    }),
    ...mapActions('conseilLocal', {
      loadConseilLocaux: 'loadFilterConseilsLocaux',
    }),
    dontHaveChange() {
      return this.editedNiveau.libelle == this.niveau.libelle &&
        this.editedNiveau.montant == this.niveau.montant &&
        this.editedNiveau.montant_local == this.niveau.montant_local &&
        this.editedNiveau.montant_surcharge == this.niveau.montant_surcharge &&
        this.editedNiveau.position == this.niveau.position &&
        (this.editedNiveau.code_comptable == undefined || this.editedNiveau.code_comptable.id == this.niveau.code_comptable.id) &&
        (this.niveau.cl == undefined || this.niveau.cl.id !== null || this.editedNiveau.cl.id == null);
    },
    save() {
      this.dialog = false;
      this.$validator.pause();
      if(this.niveau.cl !== undefined && this.niveau.cl.id === null) {
          this.editedNiveau.cl = this.conseilLocaux.find(el => el.id == this.editedNiveau.cl.id);
      }
      this.$emit('save', this.editedNiveau);
    },
  },
};

function findLibelleFromId(val) {
  const codeComptableAdhesionEnLigne = this.codesComptablesAdhesionEnLigne ?
                                          this.codesComptablesAdhesionEnLigne.find(el => el.id == val) :
                                          undefined;
  const codeComptableAdhesionManuelle = this.codesComptablesAdhesionManuelle ?
                                          this.codesComptablesAdhesionManuelle.find(el => el.id == val):
                                          undefined;

  if (codeComptableAdhesionEnLigne) {

    return codeComptableAdhesionEnLigne.libelle;

  } else if (codeComptableAdhesionManuelle){
    
    return codeComptableAdhesionManuelle.libelle;
  }
  
  return undefined;
}
</script>